<template>
  <div class="extration-bdd-frais">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="showFacturesToAvoir"
        >
          <font-awesome-icon icon="check-double" />
        </div>
      </template>
      <span>Générer des avoirs</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Génerer des factures Avoirs</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>

        <v-card-text class="body-card mt-2">
          <v-label class="mb-3">
            Voulez vous générer des factures avoirs pour le(s) factures(s) ?
          </v-label>
          <v-row v-if="permissionDate" class="mt-4 mb-4">
            <v-col cols="12">
              <v-menu :close-on-content-click="true" offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Date de l'avoir"
                    outlined
                    readonly
                    hide-details
                    v-on="on"
                    :persistent-placeholder="true"
                    clearable
                    placeholder="Date"
                    prepend-inner-icon="mdi-calendar"
                    :autofocus="false"
                    color="#704ad1"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  locale="fr"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-date-picker> </v-menu
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols>
              <v-select
                :items="templateSocieteWithoutDefault"
                class="select-menu"
                label="Template "
                :persistent-placeholder="true"
                v-model="template_id"
                no-data-text=""
                item-text="name"
                item-value="id"
                outlined
                dense
                :auto-focus="false"
                color="#704ad1"
                item-color="#704ad1"
                :menu-props="{ bottom: true, offsetY: true }"
              >
              </v-select
            ></v-col>
          </v-row>
          <v-chip-group active-class="deep-purple--text text--accent-4" column>
            <v-chip
              v-for="(item, index) in tableToUse"
              :key="index"
              :value="item"
              class="color-chips"
              text-color="#201d28"
              outlined
              :ripple="false"
              pill
            >
              {{ item.num }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleGenerate"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Générer
          </v-btn>
          <v-btn text @click="closeModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--RAPPORT CREATION FACTURE-->
    <v-dialog
      v-model="errorModal"
      max-width="1800px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Rapport Création Facture(s) Avoir(s)
          </v-label>
          <v-btn
            color="#704ad1"
            outlined
            small
            class="block-icon-table-facture"
            title="Télecharger  cette contenue"
            @click.prevent="downloadContentAutoFacture()"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></v-btn>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('errorModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <v-row
            :class="{
              'mt-4': created.length || deja_exist.length || no_seq.length
            }"
            ref="divRefFacture"
          >
            <v-col cols="12" v-if="created.length">
              <div class="titleFiche">
                Facture(s) ajoutée(s) avec succès ({{ created.length }}):
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">
                        Numero document corrigé
                      </th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        Nombre des produits
                      </th>
                      <th class="text-center dark-class">Objet</th>
                      <th class="text-center dark-class">Template</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in created" :key="index">
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">
                        {{ item.numero_document_corrige }}
                      </td>
                      <td class="text-center">{{ item.montant_ttc }} €</td>
                      <td class="text-center">{{ item.number_of_products }}</td>
                      <td class="text-center">{{ item.objet }}</td>
                      <td class="text-center">{{ item.template }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" v-if="deja_exist.length">
              <div class="titleFiche">
                Facture(s) déjà existant(s) ({{ deja_exist.length }}) :
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numero Facure</th>
                      <th class="text-center dark-class">
                        Numero document corrigé
                      </th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        Nombre des produits
                      </th>
                      <th class="text-center dark-class">Objet</th>
                      <th class="text-center dark-class">Template</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in deja_exist" :key="index">
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">
                        {{ item.numero_document_corrige }}
                      </td>
                      <td class="text-center">{{ item.montant_ttc }}€</td>
                      <td class="text-center">{{ item.number_of_products }}</td>
                      <td class="text-center">{{ item.objet }}</td>
                      <td class="text-center">{{ item.template }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" v-if="complement_avoir.length">
              <div class="titleFiche">
                Facture(s) ayant de(s) avoir(s) partiel(s) ({{
                  complement_avoir.length
                }}) :
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numero Facure</th>

                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        Nombre des produits
                      </th>
                      <th class="text-center dark-class">Objet</th>
                      <th class="text-center dark-class">Template</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in complement_avoir" :key="index">
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">{{ item.montant_ttc }}€</td>
                      <td class="text-center">{{ item.number_of_products }}</td>
                      <td class="text-center">{{ item.objet }}</td>
                      <td class="text-center">{{ item.template }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6" v-if="no_seq.length">
              <div class="titleFiche">
                Société sans séquance ({{ no_seq.length }}):
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="content-list-groupe">
                    <v-list-item-title
                      class="title-list-sci-mensuel"
                      v-for="(item, index) in no_seq"
                      v-bind:key="index"
                      >{{ item }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn outlined @click.prevent="hideModal('errorModal')">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      error: null,
      created: [],
      no_seq: [],
      deja_exist: [],
      complement_avoir: [],
      tableToUse: [],
      errorModal: false,
      date: null,
      template_id: null
    };
  },
  props: {
    functionGenerate: { required: true },
    selected: { required: true },
    famille: { required: true },
    hiddenContent: { required: false },
    permissionDate: { required: true }
  },
  computed: {
    ...mapGetters(['templateSocieteWithoutDefault'])
  },
  methods: {
    ...mapActions(['getAllTemplateSociete']),

    async openModal() {
      this.template_id = null;
      let now = new Date();
      this.date = moment(now).format('YYYY-MM-DD');
      this.dialog = true;
      const response = await this.getAllTemplateSociete(this.famille);
      if (response === true) {
        this.template_id = this.templateSocieteWithoutDefault.length
          ? this.templateSocieteWithoutDefault[0].id
          : null;
      }
    },
    closeModal() {
      this.dialog = false;
      this.loading = false;
      this.error = null;
    },
    downloadContentAutoFacture() {
      html2pdf(this.$refs.divRefFacture, {
        margin: 0.5,
        filename:
          'Rapport' +
          moment(Date.now()).format('YYYY-MM-DD') +
          this.famille +
          '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'landscape' }
      });
    },
    hideModal(ref) {
      this[ref] = false;
      this.loading = false;
      this.error = null;
      this.created = [];
      this.deja_exist = [];
      this.no_seq = [];
      this.complement_avoir = [];
      this.checkAll = true;
      this.$emit('RefreshData');
    },
    async handleGenerate() {
      this.error = null;
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append('date', this.date);
      if (this.template_id) {
        bodyFormData.append('template_id', this.template_id);
      }
      for (let i = 0; i < this.tableToUse.length; i++) {
        bodyFormData.append('ids[' + [i] + ']', this.tableToUse[i].id);
      }
      const response = await this.functionGenerate(bodyFormData);
      if (response.success) {
        this.loading = false;
        // this.hideModal('dialog');
        this.error = null;
        this.loading = false;
        this.created = response.response.created;
        this.no_seq = response.response.no_seq;
        this.deja_exist = response.response.deja_exist;
        this.complement_avoir = response.response.complement_avoir;

        if (
          this.created.length == 0 &&
          this.no_seq.length == 0 &&
          this.deja_exist.length == 0 &&
          this.complement_avoir.length == 0
        ) {
          this.closeModal();
          this.$emit('RefreshData');
        } else {
          this.dialog = false;
          this.errorModal = true;
        }
      } else {
        this.error = response.errors;
        this.loading = false;
      }
    },
    showFacturesToAvoir() {
      this.tableToUse = [];
      for (let i = 0; i < this.selected.length; i++) {
        for (let j = 0; j < this.selected[i].factures.length; j++) {
          if (
            this.selected[i].factures[j] &&
            this.selected[i].factures[j].check == true &&
            this.selected[i].factures[j].avoir_id == null &&
            (this.selected[i].factures[j].type == 'facture' ||
              this.selected[i].factures[j].type == 'Facture')
          ) {
            this.tableToUse.push(this.selected[i].factures[j]);
          }
        }
      }
      this.openModal();
    }
  },
  components: {}
};
</script>
<style lang="scss">
.paragraph-style {
  color: #343a40 !important;
  font-family: sans-serif;
  font-size: 15px;
}
</style>

<style scoped lang="scss">
.titleFiche {
  color: #704ad1;
  font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif;
}
.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
<style lang="scss">
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
